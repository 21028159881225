@use 'components/shared/index.scss' as *;
@use 'sass:color';

.App.dark {
  background-color: $grey-darkest;
  * {color: $off-white}

  .clear-modal .modal-container {
    background-color: transparent;
  }

  .modal-container {
    background-color: $grey-darkest;
  }
  .modal-tab-bar {
    border-bottom-color: $secondary-color-dark;
  }  
  .modal-tab {
    background: $grey-dark;
    &.selected {
        background: $secondary-color-dark;
    }
  }

  .main-menu-container {
    box-shadow: none;
    background-color: $grey-darkest;
    border-bottom: 2px solid $off-white;
  }
  .main-menu {
    background-color: $grey-darkest;
    &::-webkit-scrollbar-thumb {
      background-color:  $secondary-color-darker;
    }
  }
  .menu-item {
    background-color: $grey-darkest;
    &:hover, &.selected {
      background-color: $grey-darker;
    }
  }
  .menu-item-children-menu {
    background-color: $grey-darkest;
    &.open {
      border: 1px solid $white;
      border-top: none;
    }
  }
  .main-menu-toggle-container .button {
    background-color: $grey-darker;
    &:hover { background-color: $grey-dark }
  }
  .header-link {
    color: $secondary-color;
  }
  .page-tabs-bar {
    border-color:  $secondary-color-darker;
    &::-webkit-scrollbar-thumb {
      background-color: $secondary-color;
      border-radius: $radius-small;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
        background-color: $secondary-color-darker;
    }
  }
  .tab {
    background-color: $grey-darker;
    &.selected {
      background-color: $secondary-color-darker;
    }
  }
  .page-menu-toggle {
    background-color: $secondary-color-dark;
  }
  .page-menu {
    background-color: $secondary-color-dark;
    * {color: $off-white};
  }
  .page-content {
    border: 1px solid $off-white;
    background-color: $grey-darker;
  }
  .card-loading-mask {
    background-color: $grey-darkest;
    &::before {
      background-color: $grey-dark;
    }
  }

  .button {
    background-color: $secondary-color-darker;
  }

  .input-container, .dropdown-container {
    &.disabled, &.display-only {
      .input-cover-display, .input-display {
        background-color: $grey-darker;
        border: 1px solid $grey-darker;
        color: $grey;
      }
    }
    &.error .input-display{
      border: 1px solid $red;
    }
  }
  .input-mask {
    background-color: $grey-dark;
    &::before {
      background-color: $grey;
    }
  }
  .input-cover-display {
    border: 1px solid $grey-dark;
    background-color: $grey-dark;
  }
  .input-display {
    background-color: $grey-dark;
    &::selection {
      background-color: $grey;
    }
    &:focus {
      border: 1px solid $off-white;
    }
    &::placeholder {
      color: $grey-light;
    }
  }
  .input-error-message{
    color: $red;
  }

  .dropdown-menu {
    background-color: $grey-dark;
  }
  .dropdown-arrow {
    background-color: $grey-darker;
  }

  .link-container {
    .link-target {
        color: $secondary-color-lighter;
        &.disabled {
            color: $grey-dark;
        }
    }
  }
  
  .table {
    border: none;
  }
  .table-search-bar {
    background-color: $grey-darkest;
    border-bottom: 1px solid $off-white;
    .dropdown-option, .input-display {
      background-color: $grey-dark;
      color: $off-white;
    }
  }
  .table-header-cell {
    background-color: $grey-darkest;
  }
  .table-body {
    background-color: $grey-darker;
  }
  .table-loading-mask {
    background-color: $grey-darkest;
    border-bottom: 1px solid $off-white;
    &::before {
        background-color: $grey-dark;
    }
  }
  .cell-negative * {color: $red};
  .table-row {
    &.empty {
        color: $grey;
    }
    &.selected {
      background-color: color.adjust($grey-dark, $lightness: -4%);
      &:hover {
          background-color: color.adjust($grey-dark);
        }
    }
    &:hover {
      background-color: color.adjust($grey-dark, $lightness: -4%);
    }
    &.total-container {
      background-color: $grey-darker;
      color: $off-white;
    }
    &.total {
      background-color: $grey-darkest;
      color: $off-white;
    }
  }
  .table-subtotal-container {
    .subtitle .table-cell, .subtotal .table-cell {
      background-color: $grey-darkest;
      border: none;
    }
  }
  .table-nav-bar {
    background-color: $grey-darkest;
  }
  .row-count-display {
    background-color: $grey-dark;
    color: $off-white;
    &.open {
      border-color: $secondary-color-dark;
      border-top: none;
    }
  }
  .row-count-menu {
    background-color: $grey-darker;
    &.border{
      border-bottom: none;
      border-color: $secondary-color-dark;
    }
  } 
  
  .table-page-selector-numbers {
    .table-page-selector-number, .table-page-selector-concat {
        background-color: transparent;
    }
  }

  .table-page-selector-button, .table-page-selector-concat {
    background-color: $grey-dark;
    &.current {background-color: $secondary-color-dark};
    &.disabled {
        color: $grey-dark;
        background-color: $grey-darker;
    }
  }

  .checkmark.checked .checkmark-check {
    * {color: $off-white};
  }
  
  .date-range-picker {
    * {color: $off-white;}
    .react-datepicker__header {
      background-color: $grey-darkest;
    }
    input,
    .react-datepicker__month-container,
    .react-datepicker__children-container {
      background-color: $grey-dark;
    }
    input:focus {
      border: 1px solid $off-white;
    }
  }
  
  .react-datepicker__day {
    &:hover {
      background-color: $grey;
    }
  }

  .delete-accounts-user-line .delete-account-selected {
    background-color: $secondary-color-dark;
  }

  .permissions-users-role-line {
    background-color: $secondary-color-darker;
  }

  .placeholder {
    color: $grey;
  }

  @each $color-name, $color-value in $colors {
    .#{$color-name} {
        background-color: $color-value;
    }
  }

  @each $name, $gradient in $gradients {
      .grad-#{$name} {
          background: $gradient;
      }
  }
}
  