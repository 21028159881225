@use 'components/shared/index.scss' as *;
.multicheck-modal.modal-backdrop {
    .modal-container {
        width: 80%;
        max-width: 450px;
        padding: $size-xxs;
        border-radius: $radius;
        background: $white;
    }
    .modal-title {
        width: 80%;
        margin-bottom: $size-3xs;
        font-weight: bold;
        color: $primary-color;
    }
    .list-container{
        width: 100%;
        height: 100%;
        max-height: 400px;
        overflow-y: scroll;
        background-color: $grey-lightest;
        border-radius: $radius;
        scrollbar-width: $size-small;
        scrollbar-gutter: stable;
        box-sizing: border-box;
    }
    .list-row {
        width: 100%;
        padding-left: $size-3xs;
        height: $size-xs;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        box-sizing: border-box;
    }

    .list-label {
        height: $size-xxs;
        line-height: $size-xxs;
        color: $primary-color;
    }

    .list-state-icon {
        display: block;
        position: relative;
        width: var(--size-font);
        height: var(--size-font);
        margin-right: $size-5xs;
        border: 1px solid $primary-color;
        background-color: $primary-color-lighter;
        border-radius: $size-6xs;
        transition: all .3s;
        .checkmark {
            position: absolute;
            height: $size-xs;
            width: $size-xs;
            border-radius: $radius-small;
            transition: all .3s;
            background-color: transparent;
            &:after{
                position: absolute;
                border: solid $primary-color-lighter;
            }
        }
        &.success {
            background-color: $primary-color-lightest;
            border-color: $green;
            border-radius: 50%;
            .checkmark:after {
                top: 1px;
                left: 4px;
                content: '';
                width: 3px;
                height: 7px;
                border: solid $green;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        &.fail {
            background-color: $primary-color-lightest;
            border-color: $red;
            .checkmark:after {
                position: absolute;
                top: 6px;
                left: 7px;
                font-weight: bold;
                border: none;
                content: 'X'; /* Add content property with 'x' value */
                color: $red;
                transform: translate(-50%, -50%); /* Optional: Center the 'x' */
            }
        }
    }
}

.multicheck-close-button{
    margin-top: $size-3xs;
}
