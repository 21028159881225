@use 'components/shared/index.scss' as *;

.date-range-picker {
  margin-right: $size-3xs;
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .input-label.hide-label {
    height: 0px;
    min-height: 0px;
  }
  input {
    width: 100%;
    margin: 0px;
    padding-left: $size-3xs;
    padding-right: $size-3xs;
    border-radius: $radius-small;
    border: none;
    box-sizing: border-box;
    outline: none;
    overflow:hidden;
    text-overflow: ellipsis;
    height: var(--input-height);
    cursor: pointer;
    box-sizing: border-box;
    &:focus {
      border: 1px solid $secondary-color;
    }
  }
  .react-datepicker-popper {
    z-index: 5;
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--keyboard-selected {
    background-color: $secondary-color !important;
    color: $white;
  }

  $picker-width: 330px;

  .react-datepicker {
    width: $picker-width;
  }

  .react-datepicker__triangle {
    left: -50px !important;
    &::after {
      border-bottom-color: $white !important;
    }
  }
  .react-datepicker__navigation--previous {
    left: calc($picker-width - 210px + 2px) //New width + calendar width + original position
  }
  .react-datepicker__month-container {
    float: right;
  }
  .react-datepicker__children-container {
    position: absolute;
    width: calc($picker-width - 210px);
    margin: 0px;
    height: 100%;
    padding: $size-5xs;
    border-right: 1px solid $grey-light;
    box-sizing: border-box;
  } 
}
.date-range-picker-options-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.date-range-option {
  color: $secondary-color;
  font-weight: bold;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    color: $secondary-color-light;
  }
  &:active {
    transform: scale(0.97);
  }
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
  .date-range-picker .react-datepicker {
    height: 210px;
    width: 120px; 
  }
  .react-datepicker__month-container {
    display: none;
  }
  .react-datepicker__navigation.react-datepicker__navigation--previous {
    display: none;
  }
  .react-datepicker__navigation.react-datepicker__navigation--next {
    display: none;
  }
}