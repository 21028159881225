@use 'components/shared/index.scss' as *;

.full-page.page {
    grid-column: span 12;
}

.permissions-users-role {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: $size-large;
    text-align: center;
}

.permissions-users-role-line {
    width: 100%;
    height: $size-mid;
    border: 1px solid $grey;
    border-radius: $radius-small;
    transition: background-color .1s ease;
    background-color: $secondary-color-lighter;
    font-weight: bold;
    line-height: $size-mid;
    text-align: center;
}

.permissions-users-label {
    height: $size-xxs;
    min-height: $size-xxs;
    padding-left: $size-5xs;
    margin-left: $size-4xs;
    margin-bottom: $size-5xs;
    font-weight: bold;
    box-sizing: border-box;
}

.permissions-locations-list-content {
    border: 1px solid $grey-dark;
    border-radius: $radius-small;
}

.permissions-location-item {
    display: flex;
    position: relative;
    flex-direction: row; 
    justify-content: flex-start;
    align-items: center;
    height: $size-mid;
    color: $grey-darker;
    &.filler {
        color: $grey-light;
        justify-content: center;
    }
}

.permissions-location-remove-button {
    height: $size-xs;
    border-radius: $radius-large;
    margin-left: $size-3xs;
}

.permissions-location-label {
    margin-left: $size-3xs;
}