@use 'components/shared/index.scss' as *;

// Default
.checkbox-container {
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    .checkmark-check {
        * {color: $grey-lightest};
    }
    &.hide-label {
        .checkbox-label {
            display: none;
        }
    }
    &.label-space {
        margin-top: $size-xxs;
    }
    &.display-only {
        pointer-events: none;
        cursor: default;
        .checkmark {
            background-color: $grey;
            * { color: $grey};
        }
        .checkmark.checked {
            border-radius: $radius-small;
            background-color: $green;
            * { color: $white};
        }
    }
    &.disabled {
        pointer-events: none;
        cursor: default;
        .checkmark {
            filter: brightness(0.7);
        }
    }
    &.error .checkmark{
        border: 1px solid red;
    }
}

.checkbox-label {
    height: $size-xxs;
    min-height: $size-xxs;
    text-align: center;
    color: $primary-color-darker;
    margin-bottom: $size-6xs;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;
    box-sizing: border-box;
}

.checkmark-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 24px;
    padding-top: 8px; // with the checkmark height of 24 px, this matches the checkbox to input heights
}

.checkmark-check {
    position: absolute;
    left: 0px;
    height: $size-xs;
    line-height :$size-xs;
}

.checkmark {
    position: relative;
    height: $size-xs;
    width: $size-xs;
    background-color: $grey-lightest;
    border-radius: $radius-small;
    transition: all .3s;
    cursor: pointer;
    &.radio {
        border-radius: 50%;
    }
    &.checked {
        background-color: $green;
        border-radius: 50%;
        .checkmark-check {
            color: $white;
        }
    }
    &:hover {
        filter: brightness(105%);  
    }
}

.checkmark-x.icon {
    background-color: $red;
    border-radius: $radius-small;
    color: $white;
}

.checkbox-container.left {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    height: $size-mid;
    .checkmark-container {
        padding: 0px;
        width: $size-small;
        
    }
    .checkbox-label {
        padding: 0px;
        margin-left: $size-4xs;
        text-align: left;
    }
}

.checkbox-container.right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: $size-mid;
    .checkmark-container {
        margin: 0px;
        width: $size-small;
        padding: 0px;
    }
    .checkbox-label {
        margin: 0px;
        margin-right: $size-4xs;
        text-align: right;
    }
}

.checkbox-mask {
    position: absolute;
    left: 0px;
    height: $size-xs;
    width: $size-xs;
    border: none;
    border-radius: $radius-small; 
    box-sizing: border-box;
    opacity: 1;
    z-index: 3;
    background-color: $grey-mask;
    transition: opacity 0.1s ease-out;
    overflow: hidden;
    cursor: default;
    &.fading-mask {
        opacity: 0;
    }
    &.faded {
        opacity: 0;
        z-index: -1; 
    }
    &::before {
        content: '';
        position: absolute;
        left: -10%;
        width: 40%; 
        height: 500%; 
        pointer-events: none; 
        background-color: $mask-wipe;
        z-index: 3; 
        filter: blur($size-5xs);
        animation: ripple-mask-move 1.4s linear infinite;
        transition: opacity 0.1s ease-out;
        transform: translateY(-50%) rotateZ(45deg); // Rotate the element to move diagonally
    }
}

.checkbox-error-message {
    height: var(--size-font-large);
    line-height: var(--size-font-large);
    color: $red;
    text-align: center;
    margin-top: 8px; // to align with Input errors
    overflow: hidden;
    box-sizing: border-box;
}

.checkbox-mask.fading-pseudo::before {
    opacity: 0;
}

.checkbox-container.table-searchbar {
    .checkmark {
        background-color: $white;
        &.checked {
            background-color: $green;
        }
    }
    .checkbox-mask {
        background-color: $white;
        &::before {
            background-color: $grey-lightest;
        }
    }
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .checkmark-container {padding-top: 0px}
}