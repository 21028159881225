@use 'components/shared/index.scss' as *;

.table-header-cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    * { font-size: var(--size-font) };
    overflow: hidden;
    background-color: $white;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 3;
    &.icon {
        width: $cell-icon;
        min-width: $cell-icon;
    }
    &.xs {width: $cell-xs}
    &.small {width: $cell-small}
    &.mid {width: $cell-mid}
    &.large {width: $cell-large}
    &.xl {width: $cell-xl}
    &.hidden {display:none}
    &.text-left {
        justify-content: flex-start;
        .table-header-cell-contents {
            text-align: start;
            margin-left: $size-4xs;
            max-width: calc(100% - $size-3xs);

        }
    }
    &.text-right {
        justify-content: flex-end;
        .table-header-cell-contents {
            justify-content: flex-end;
            text-align: right;
            max-width: calc(100% - $size-small);
            margin-right: $size-small;
        }
    }
}

.table-header-cell-contents {
    position: relative;
    width: fit-content;
    max-width: 75%;
}

.table-header-sort-arrow {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);  // Adjust it to be perfectly centered
    height: $size-3xs;
    width: $size-3xs;
    cursor: pointer;
    &.hide {
        display: none;
    }
    svg {
        border: 1px solid $secondary-color;
        color: $secondary-color;
        border-radius: 50%;
        height: $size-3xs;
        width: $size-3xs;
    }
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .table-header-sort-arrow {
        right: -14px;
        width: 10px;
        height: 10px;
        cursor: pointer;
        svg {
            height: 10px;
            width: 10px;
        }
    }
    .phone .table-header-cell {
        &.text-left .table-header-cell-contents {
            margin-left: $size-6xs;
            max-width: calc(100% - $size-6xs);
        };
        &.text-right .table-header-cell-contents {
            margin-right: 20px;
            max-width: calc(100% - 20px);
        }
    }
}