@use 'components/shared/index.scss' as *;

.card {
    display: flex;
    flex-direction: column;
    justify-content:stretch;
    height: auto;
    max-height: 100%;
    box-sizing: border-box;
    &.margin-top {
        padding-top: $size-3xs;
    }
    &.margin-bottom {
        margin-bottom: $size-5xs;
    }
    &.margin-left {
        margin-left: $size-4xs;
    }
}

.card-label {
    font-size: var(--size-font-large);
    color: $primary-color-darker;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    &.hide-label {
        height: 0px;
        min-height: 0px;
    }
}

.card-content.grid {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: $radius;
    box-sizing: border-box;
}

.card.centered {
    .card-content.grid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: auto;
    }
}

.card-loading-mask {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: $white;
    transition: all .2s;
    z-index: 3;
    overflow: hidden;
    border-radius: $radius;
    &.hide {
        opacity: 0;
        z-index: -1;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 40%;
        height: 500%;
        pointer-events: none;
        background-color: $off-white;
        z-index: 2;
        filter: blur($size-mid);
        animation: ripple-mask-move 1.4s linear infinite;
        transition: opacity 0.1s ease-out;
        transform: translateY(-50%) rotateZ(45deg); // Rotate the element to move diagonally
    }
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .card {
        grid-column: span 12 !important;
        padding-left: $size-6xs;
        padding-right: $size-6xs;
    }
    .card.margin-top {
        padding-top: $size-6xs;
    }
}