@use 'components/shared/index.scss' as *;

.link-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    .link-target {
        width: auto;
        color: $secondary-color;
        cursor: pointer;
        &.disabled {
            color: $secondary-color-dark;
            cursor: none;
            pointer-events: none;
        }
    }
    i {
        width: 20px;
        height: 20px;
        svg {
            height: 20px;
            width: 20px;
        }
    }
}

.link-spinner.spinner {
    width: $size-4xs;
    height: $size-4xs;
    border-radius: 50%;
    display: block;
    position: relative;
    border: $size-5xs solid;
    animation: link-animloader 2s linear infinite;
    &.grey {
        animation: link-animloader-alt 2s linear infinite;
    }
    
      $cleared: rgba(255, 255, 255, 0);
    
      @keyframes link-animloader {
        0% {
            transform: rotate(0deg);
            border-color: $cleared $cleared $cleared;
        }
        25% {
            border-color: $secondary-color-light $secondary-color-light $cleared $cleared;
        }
        50% {
            border-color: $secondary-color-light $secondary-color-light $secondary-color-light $cleared
        }
        75% {
            border-color: $cleared $cleared $cleared $secondary-color
        }
        100% {
            transform: rotate(360deg);
            border-color: $secondary-color-light $cleared $cleared $cleared
        }
      }
      
      @keyframes link-animloader-alt {
        0% {
            transform: rotate(0deg);
            border-color: $secondary-color-lightest $cleared $cleared $cleared;
        }
        25% {
            border-color: $secondary-color-lightest $secondary-color-lightest $cleared $cleared;
        }
        50% {
            border-color: $secondary-color-lightest $secondary-color-lightest $secondary-color-lightest $cleared;
        }
        75% {
            border-color: $cleared $cleared $cleared $secondary-color-lightest;
        }
        100% {
            transform: rotate(360deg);
            border-color: $grey-lightest $cleared $cleared $cleared
        }
      }
      
}

a {
    color: $white;
    text-decoration: none;
}

a:visited {
    color: inherit;
}
