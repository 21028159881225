@use 'components/shared/core.scss' as *;

.clear-modal.modal-container .modal-content {
   height: 100%;
   justify-content: center;
}

.qr-image {
   width: 200px;
   height: 200px;
}

.qr-close {
   width: 150px;
}