@use 'components/shared/index.scss' as *;

.table {
    width: 100%;
    border: 1px solid $grey-lightest;
    border-radius: $radius;
    &.freeze {
        pointer-events: none !important;
    }
}

.table-title {
    font-size: var(--size-font-large);
    margin: $size-5xs;
    margin-left: $size-4xs;
    box-sizing: border-box;
    &.margin-top {
        margin-top:  $size-4xs;
    }
    &.no-margin-top {
        margin-top: 0;
    }
}

.table-spacer {
    margin-bottom: $size-3xs;
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .phone .table {
        translate: -$size-5xs;
        width: calc(100% + (2 * $size-5xs));
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
    }
}