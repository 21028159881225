
@use 'components/shared/index.scss' as *;

.main-menu-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 54px;
    background-color: $secondary-color;
    box-shadow: 0 5px 8px #ccc;
    z-index: 5;
    box-sizing: border-box;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: $size-3xs;
    cursor: pointer;
    .logo-image {
        height: $size-large;
    }
}

.main-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:auto;
    height: $size-xl;
    transition: all .2s;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: $secondary-color;
    overflow-x: auto;
    &::-webkit-scrollbar {
        height: $size-4xs;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $tertiary-color;
        border-radius: 10px;
        cursor: pointer;
    }
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .main-menu-container {height: 40px};
    .logo .logo-image {
        height: 36px;
    }
    .main-menu {
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
        top: 38px; // not sure why atm.
        right: 0px;
        max-height: 0px;
        width: 180px;
        overflow-y: scroll;
        border-left: 2px solid $off-white;
        &.open {
            max-height: calc(100% - 38px); // 38 so it covers the 2px-thick border;
            height: calc(100% - 38px);
        }
        &::-webkit-scrollbar {
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            cursor: pointer;
        }
    }
    .main-menu-toggle-container {
        height: $size-xl;
        width: $size-xl;
    }
    .main-menu-toggle.button {
        margin-top: $size-6xs;
        width: 36px;
        .icon {
            width: $size-xxs;
            height: $size-xxs;
            svg {
                width: $size-xxs;
                height: $size-xxs;
            }
        }
    }
}