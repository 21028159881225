@use 'components/shared/index.scss' as *;

.delete-account-search-button {
    margin-top: $size-xs;
}

.delete-accounts-column-header {
    font-weight: bold;
    padding-left: $size-3xs;
}

.delete-accounts-no-results {
    height: $size-large;
    line-height: $size-large;
    text-align: center;
    opacity: .6;
}

.delete-accounts-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 800px;
    margin-bottom: $size-3xs;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    :nth-child(1).delete-accounts-user-line {
        border-top-right-radius: $radius;
        border-top-left-radius: $radius;
    }
    :last-child.delete-accounts-user-line {
        border-bottom-right-radius: $radius;
        border-bottom-left-radius: $radius;
        border-bottom: 1px solid $grey;
    }
}

.delete-accounts-user-line {
    border: 1px solid $grey;
    border-bottom: none;
    transition: background-color .1s ease;
    box-sizing: border-box;
    &.delete-account-selected {
        background-color: $secondary-color-lightest;
    }
}

.delete-accounts-select-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    .delete-accounts-select-button.standard-button {
        width: 80%;
    }
}

.delete-accounts-user-cell {
    height: $size-large;
    line-height: $size-large;
    color: $primary-color-darker;
    text-align: center;
    padding-left: $size-3xs;
    text-align: left;
    &.delete-accounts-header {
        font-weight: bold;
    }
    &.name {
        width: 35%;
    }
    &.email {
        width: 65%;
    }
}

.delete-accounts-user-button-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: $size-mid;
    line-height: $size-mid;
    color: $primary-color-darker;
    text-align: left;
}