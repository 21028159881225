@use 'components/shared/index.scss' as *;
@use 'sass:color';

.cash-display-container {
    grid-column: auto / span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    text-align: center;
    box-sizing: border-box;
    &.stacker {
        .cash-display {
            background-color: $purple;
        }
    }
}

.cash-display-label {
    margin-top: $size-4xs;
    font-size: var(--size-font-mid);
}

.cash-display {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 200px;
    width: 100%;
    border-radius: $radius;
    background-color: $teal;
    padding-top: $size-6xs;
    padding-bottom: $size-4xs;
    box-sizing: border-box;
}

.cash-display-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    * {color: $white}
    font-weight: bold;
}

.cash-display-bar-container {
    position: relative;
    width: 45%;
    height: 100%;
}

.cash-display-bar-display {
    position: absolute;
    width: 100%;
    bottom : 0;
    z-index: 1;
}

.cash-display-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    background-image: linear-gradient(135deg, color.scale($green, $lightness: 20%) 0%, $green 60%);
    transition: all .2s;
    z-index: 0;
}

.cash-display-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.cash-display-values {
    width: 70%;
    border-top: 2px solid $white;
    box-sizing: border-box;
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .cash-display { height: 150px };
}