@use 'components/shared/index.scss' as *;

.user-password-error {
    min-height: $size-xs;
    text-align: center;
    color: $red;
}

.password-reqs-container {
    .password-req {
        height: $size-small;
    }
}

.no-pin-changing-message {
    padding-left: $size-mid;
    padding-right: $size-mid;
    text-align: center;
    color: $secondary-color;
    box-sizing: border-box;
}