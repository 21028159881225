@use 'components/shared/index.scss' as *;

.device-status-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: $size-xxs;
    text-align: left;
    font-weight:bold;
    margin-left: $size-xs;
    font-size: var(--size-font);
    color: $primary-color-darkest;
}

.device-status-result {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: $size-xxs;
    line-height: var(--size-font);
    text-align: center;
    font-weight: bold;
    color: $red;
    &.success {
        color: $green;
    }
}

.device-commission-button {
    min-width: $size-5xl;
}

.device-details-label {
    font-weight: bold;
    margin-bottom: $size-6xs;
}

.device-cash-section-label {
    margin-top: $size-xxs;
    text-align: center;
    * { font-size: var(--size-font-large) }
}

.device-details-value {
    text-align: end;
}

.assignment-top.card {
    margin-top: $size-xxs;
}

.device-anydesk-container {
    position: relative;
}

.device-anydesk {
    position: absolute;
    left: 140px;
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .device-statuses-card.full{
        .two-thirds { grid-column: span 6 }
    }
}