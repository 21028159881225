@use 'components/shared/index.scss' as *;
.device-action-modal {
  &.modal-backdrop {
    .modal-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 300px;
    }

    .action-message {
      font-size: var(--size-font-mid);
      color: $primary-color;
      height: 100%;
      text-align: justify;
    }
    .action-error-message {
      font-size: var(--size-font-mid);
      color: $red;
      height: 100%;
      text-align: justify;
    }
    .modal-content {
      height: 100%;
      justify-content: space-between;
    }
  }
}

.device-action-modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}