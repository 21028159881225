@use 'components/shared/index.scss' as *;

.login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    .input-label {
        color: $white;
    }
}

.login-form-container {
    width: 80%;
    max-width: 600px;
    background-color: $grey-darkest;
    border-radius: $radius;
    padding-bottom: $size-xxs;
}

.login-logo {
    margin-top: $size-3xs;
    margin-bottom: $size-3xs;
    text-align: center;
    .login-logo-image {
        max-width: 100%;
        max-height: 100px;
    }
}

.login-notice {
  text-align: center;  
  margin-bottom: $size-4xs;
}

.login-error {
    height:$size-xxs;
    min-height: $size-xxs;
    line-height: $size-xxs;
    margin-bottom: $size-5xs;
    text-align: center;
    color: $red;
}

.login-link-container {
    margin-top: $size-3xs;
}

.login-padding {
    margin-bottom: $size-3xs;
}