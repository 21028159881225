@use 'components/shared/index.scss' as *;

.page {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    margin-bottom: $size-3xs;
    &.has-tabs .page-content {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}

.page-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: $page-header-height;
    box-sizing: border-box;
}

.page-tabs-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: $size-large;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-gutter: stable;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        height: $size-5xs;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $tertiary-color;
        border-radius: $radius-small;
        cursor: pointer;
    }
    &::-webkit-scrollbar-track {
        background-color: $secondary-color;
    }
}

.page-content {
    height: 100%;
    width :100%; 
    min-height: $size-3xl;
    padding: $size-4xs;
    border-radius: $radius;
    background-color: $white;
    box-sizing: border-box;
    &.grid {
        grid-row-gap: 0px;
    }
}

.page-missing-data {
    width: 100%;
    margin-top: $size-xxl;
    text-align: center;
    line-height: $size-mid;
    color: $orange;
}

.no-page-content {
    text-align: center;
}

.page-spinner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: $size-large;
    .page-spinner {
        height: $size-mid;
        width: $size-mid;
    }
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .page {margin-bottom: 0px};
    .page-header {height: $page-header-height};
    .page-tabs-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: $size-large;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-gutter: stable;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            height: $size-5xs;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $tertiary-color;
            border-radius: $radius-small;
            cursor: pointer;
        }
        &::-webkit-scrollbar-track {
            background-color: $secondary-color;
        }
    }
    .page-menu-item.phone {display: none};
    .page-content {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: $size-5xs;
        padding-right: $size-5xs;
    }
    .page-menu-container {
        position: absolute;
        right: $size-6xs;
        top: 4px;
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        width: $size-large;
        text-align: right;
        box-sizing: border-box;
        z-index: 3;
    }
    .page-menu-toggle {
        height: $size-small;
        background-color: $grey;
        padding: 0px;
    }
    .page-menu {
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
        top: 37px; // height of header button + space below to align to page content;
        right: -2px;
        max-height: 0px;
        width: 120px;
        transition: all .2s;
        overflow-y: scroll;
        * {color: $white};
        background-color: $grey;
        border-radius: $radius-small;
        border-top-right-radius: $radius;
        z-index: 4;
        &.open {
            max-height: 600px;
        }
        &::-webkit-scrollbar {
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            cursor: pointer;
        }
    }

    .page-menu-item {
        height: $size-mid;
        line-height: $size-mid;
        padding-left: $size-3xs;
        text-align: left;
        font-size: var(--size-font-mid);
        box-sizing: border-box;
    }
}