@use 'components/shared/index.scss' as *;

.clear-modal .modal-container .modal-content {
   height: 100%;
   justify-content: center;
}

.receipt-image {
   max-height: 98%;
   color: $white;
}

.receipt-close {
   width: 150px;
}