@use 'components/shared/index.scss' as *;

.tab-container {
    position: relative;
    display: inline-block;
    height: 100%;
    * { font-size: var(--size-font-mid) };
    &:focus {
        border: none;
        background-color: none;
    }
}

.tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: $size-4xs;
    padding-right: $size-4xs;
    min-width: $size-6xl;
    height: 100%;
    border-top-left-radius: $radius-large;
    border-top-right-radius: $radius-large;
    margin-right: $size-6xs;
    transition: all .1s;    
    cursor: pointer;
    background: $grey-lightest;
    * {color: $grey-darker}
    &.selected {
        background: $secondary-color;
        * {color: $white}
    }
    &.pointer {
        cursor: pointer;
    }
}

.tab-menu {
    position: absolute;
    display: none;
    background-color: $primary-color-light;
    border-radius: $radius;
    width: 200%; // 100% is as wide as the text above, so this will give it twice that
    right:0px;
    .tab-menu-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: $radius;
        padding: $size-3xs;
        color: $white;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            color: $secondary-color-light;
        }
        .tab-text {
            width: 100%;
            line-height: $size-xxs;
            text-align: center;
        }
    }
    &.tab-menu-open {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {

}