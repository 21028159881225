@use 'components/shared/index.scss' as *;

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--header-height);
    min-height: var(--header-height);
    box-sizing: border-box;
}

.header-label-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height :100%;
    box-sizing: border-box;
}

.header-label-container:has(.header-links-container) {
    justify-content: flex-start;
    padding-top: $size-6xs;
    padding-bottom: $size-4xs;
}

.header-label {
    font-size: var(--size-font-xl);
    margin-left: $size-xs;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;
    font-weight: 500;
    box-sizing: border-box;
}

.header-links-container {
    position: absolute;
    bottom: $size-6xs;;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: calc($size-xs + 2px);
    align-items: flex-end;
}

.header-links-divider {
    line-height: $size-3xs;
    height: $size-3xs;
    margin-right: $size-5xs;
}

.header-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: $size-3xs;
    color: $secondary-color;
    margin-right: $size-5xs;
    cursor: pointer;
}

.header-tabs-container {
    overflow: hidden;
}

.header-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: $size-3xs;
}

.header-add-button.button {
    background-color: $secondary-color;
    &.spaced {
        margin-right: $size-xs;
    }
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .header-label-container {
        width: 90%;
        :has(.header-links-container) {
            justify-content: flex-start;   
            padding: 0px;
        }
    }
    .header-label {
        margin-left: $size-6xs;
    }
    .header-links-container {
        width: 100%;
        bottom: $size-6xs;
        margin-left: $size-6xs;
    }
    .header-add-button.button.page-tabs {
        margin-right: $size-4xs;
    }
    .header-add-button.button.spaced {
        margin-right: $size-xs;
    }
}