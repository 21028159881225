@use 'components/shared/index.scss' as *;

.cash-breakdown-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    padding-left:$size-xxs;
    padding-right: $size-xxs;
    .cash-breakdown.grid {
        grid-row-gap: $size-5xs;
        grid-column-gap: 0px;
        .red-text {color: $red}    
        .right {text-align: right}
        .bold {font-weight: bold}
        .divider {
            box-sizing: border-box;
            border-bottom : 2px solid $primary-color-darker;
        }
        div {
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }  
}

.cash-breakdown-title {
    grid-column: span 4;
    text-align: center;
    font-weight: bold;
    font-size: var(--size-font-mid);
    margin-top: $size-4xs;
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .phone .cash-breakdown-container {
        grid-column: span 12 !important;
        .half { grid-column: span 6 }
        .quarter { grid-column: span 3 }
    }

    .cash-breakdown-title {
        margin-top: $size-6xs;
    }
}