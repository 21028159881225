@use 'components/shared/index.scss' as *;

.confirm-modal.modal-backdrop {
   .modal-container {
      max-width: 600px;
   }
   .modal-title {
      color: $secondary-color;
   }
   .confirm-modal-message {
      font-size: var(--size-font-large);
      margin-bottom: $size-xxs;
      text-align: center;
   }
}