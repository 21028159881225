@use 'components/shared/index.scss' as *;

.location-player-history-modal.modal-backdrop {
    height: 100%;
    .modal-container {
        width: 100%;
        max-width: 90%;
        height: 80%;
    }
    .modal-content{
        width: 100%;
        height: 100%;
    }
    .table-header-row {
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
    }
    .button {
        margin-bottom: $size-3xs;
    }
}