@use 'components/shared/index.scss' as *;

.dropdown-option {
    width: 100%;
    height: 100%;
    padding: $size-6xs $size-5xs $size-6xs $size-5xs;
    line-height: $size-xxs;
    text-align: left;
    word-wrap: break-word;
    box-sizing: border-box;
    cursor:pointer;
    &:hover {
        background-color: $secondary-color-lightest;
    }
    &:focus {
        background-color: $secondary-color-lightest;
        border: none;
        outline: none;
        &:hover {
            background-color: $secondary-color-lighter;
        }
    }
    &.placeholder:hover {
        background-color: $grey-lightest;
    }
}