@use 'components/shared/index.scss' as *;

.dropdown-container {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    &.disabled {
        pointer-events: none;
        .input-cover-display {
            border-color: transparent;
        }
        .input-display {
            cursor: default;
        }
        .dropdown-arrow {
            display: none;
        }
    }
    &.margin-top {
        margin-top: $size-4xs;
    }
    &.display-only {
        pointer-events: none;
        .input-display {
            cursor: default;
            background-color: $white;
            border: 1px solid $white;
        }
        .input-cover-display {
            display: none;
        }
        .dropdown-arrow {
            display: none;
        }
    }
}

.dropdown-container.hide-label {
    .dropdown-label {
        display: none;
    }
    .dropdown-menu {
        top: $size-mid;
    }
    .dropdown-arrow {
        top: 10px;
    }
    min-height: $size-mid;
}

.dropdown-display.input-container {
    border-radius: $radius;
    color: $primary-color-darker;
    box-sizing: border-box;
    outline: none;
    transition: all .025s;
    width: 100%;
    .input-display {
        padding-right: $size-small;
    }
    &:focus {
        border: 1px solid $secondary-color;
    }
    &.open {
        .input-display {
            border-color: $secondary-color;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            transition: all .025s;
            border-bottom: 1px solid transparent;
        }
    }
}

.dropdown-container.disabled {
    .dropdown-display{
        cursor: default;
    }
    .dropdown-arrow {
        z-index: 1;
        background-color: $grey;
        cursor: default;
    }
}

.dropdown-arrow {
    position: absolute;
    top: 32px; // accounts for label height + input
    right: $size-4xs;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: $size-xxs;
    width: $size-xxs;
    background-color: $grey-light;
    * {color: $white};
    border: none;
    border-radius:50%;
    transition: transform 0.2s ease;
    z-index: 2;
    cursor: pointer;
    pointer-events: none;
    i {
        position: relative;
        width: 14px;
        height: 15px;
        svg {
            width: 14px;
            height: 16px;
        }
    }
    &.open {
        transform: rotate(180deg);
    }
}

.dropdown-menu {
    position: absolute;
    top: calc($size-mid + $size-xs - 2px);
    width: 100%;
    max-height: 0px;
    transition: max-height .2s;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $off-white;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    border: none;
    z-index: 5;
    &.open {
        max-height: 400px;
    }
    &.border{
        border: 1px solid $secondary-color;
        border-top: none;
    }

    &::-webkit-scrollbar {
        width: $size-3xs;
    }
    &::-webkit-scrollbar-thumb {
        background: $primary-color-light;
        border-radius: $radius-small;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: $primary-color;
    }
    // If you want a scrollbar, remove this class
    &::-webkit-scrollbar {
        display: none;
    }
}

.add-icon {
    position: absolute;
    top: calc($size-small + $size-6xs);
    right: $size-mid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: $size-xxs;
    width: $size-small;
    background-color: $secondary-color;
    color: $white;
    font-weight: bold;
    border-radius:$radius;
    transition: transform 0.2s ease;
    z-index: 2;
    cursor: pointer;
    i {
        width: $size-3xs;
        height: calc($size-3xs + 1px);
        font-weight: bold;
        svg {
            width: $size-3xs;
            height: calc($size-3xs + 1px);
            font-weight: bold;
        }
    }
}

.dropdown-error-message {
    text-align: center;
    color: $red;
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .dropdown-container.phone {
        pointer-events: none;
        .input-display {
            cursor: default;
            background-color: $white;
            border: 1px solid $white;
        }
        .input-cover-display {display: none};
        .dropdown-arrow {display: none};
        .input-error-message {display: none};
    }
    .dropdown-menu {
        top: 48px;
    }
    .dropdown-arrow {
        top: 27px;
    }
}