@use 'components/shared/index.scss' as *;

.modal-backdrop {
	position: fixed;
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 50;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	&.clear-modal .modal-container{
		height: 100%;
		background: transparent;
	}
}

.modal-tab-bar {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	height: $size-large;
	border-bottom: $size-6xs solid $secondary-color;
}

.modal-tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: $size-4xs;
    padding-right: $size-4xs;
    min-width: $size-6xl;
    height: 100%;
    border-top-left-radius: $radius-large;
    border-top-right-radius: $radius-large;
    margin-right: $size-6xs;
    color: $grey;
	font-size: var(--size-font-mid);
    transition: all .1s;    
    cursor: pointer;
    background: $grey-lightest;
    &.selected {
        background: $secondary-color;
        color: $white;
    }
    &.pointer {
        cursor: pointer;
    }
}

.modal-container {
	display:flex;
	flex-direction: column;
	align-items: center;
	width: 80%;
	max-width: 800px; //arbitrary
	max-height: 90%;
	padding: $size-3xs;
	border-radius: $radius;
	background-color: $white;
}

.modal-title {
	font-size: var(--size-font-xl);
	font-weight: bold;
	color: $primary-color;
	&.warning {
		color: $orange;
	}
}

.modal-content {
	display:flex;
	flex-direction: column;
	align-items: center;
	border-radius: $radius;
	margin-top: $size-3xs;
	width: 80%;
	box-sizing: border-box;
}


.modal-table-container {
	position: relative;
	height: 85%;
	overflow-y: scroll;
	scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For IE and Edge */
	&::-webkit-scrollbar {
        width: 0px; /* For Chrome, Safari, and Opera */
    }
}


.modal-buttons-container {
	margin-top: $size-3xs;
	scrollbar-width: 0px;
}

.modal-button {
	width: 150px;
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
	.modal-container {
		position: relative;
		width: 85%;
	}
	.modal-content {
		margin-top: 0px;
		height: 100%;
		width: 100%;
		padding: 0px;
	}
	.modal-buttons-container.grid {
		margin-top: 0px;
		position: absolute;
		left: calc($size-3xs);
		display: flex;
		bottom: $size-5xs;
		width: calc(100% - (2 * $size-3xs));
		box-sizing: border-box;
	}

	.add-modal.modal-backdrop {
		.modal-container {
			height: 70%;
			margin-top: $size-3xs;
		}
		.modal-content {
			margin-top: 0px;
			max-height: calc(100% - $size-mid - (2 * $size-3xs));
			width: 100%;
			padding: 0px;
		}
	}
}
