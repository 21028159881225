@use 'components/shared/index.scss' as *;

.service-item {
    position: relative;
    border: 3px solid $secondary-color-lighter;
    border-radius: $radius;
    .checkbox-container {
        margin-top: $size-xxs;
        padding-right: $size-large;
    }
    .input-label {
        text-align: right;
        margin-right: $size-3xs;
    }
}

.service-item-contents {
    &.disabled { 
        opacity: .4;
        pointer-events: none;
    }
}

.service-item-label {
    width: 100%;
    font-weight: bold;
    margin-top: $size-xs;
    margin-bottom: $size-xs;
    font-size: var(--size-font-xl);
    line-height: var(--size-font-xl);
    text-align: center;
    height: var(--size-font-xl);
}

.service-item-logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: $size-3xs;
    height: 150px;
}

.service-item-logo {
    max-height: 150px;
    max-width: 300px;
}

.service-item-error {
    height: $size-xs;
    line-height: $size-xs;
    padding-left: $size-5xs;
    text-align: center;
    color: $red;
}

.service-item-banner {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 48%;
    width: 100%;
    background-color: $secondary-color;

}
.service-item-banner-text {
    text-align: center;
    color: $white;
    border-top: 3px solid $white;
    border-bottom: 3px solid $white;
    margin-top: $size-5xs;
    margin-bottom: $size-5xs;
    width: 85%;
    max-width: 450px;
    font-weight: bolder
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .service-item.third {
        grid-column: span 12;
        .checkbox-container {
            flex-direction: column;
            justify-content: start;
            grid-column: span 6;
            margin-top: 0px;
        }
        .input-container {
            margin: 0px;
            justify-content: flex-start;
            align-items: flex-start;
            grid-column: span 6;
            .input-cover-display {text-align: start};
        }
    }
    .service-item-label {
        margin-top: $size-3xs;
        margin-bottom: $size-3xs;
    }

    .service-item-logo-container {height: 60px}
    .service-item-logo {
        max-height: 60px;
        max-width: 300px;
    }
}