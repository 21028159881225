@use 'components/shared/index.scss' as *;

.new-stage-marker-container {
    width: 100%;
    padding-left: $size-xxs;
    padding-right: $size-xxs;
    box-sizing: border-box;
}

.new-stage-marker {
    margin-bottom: $size-3xs;
    height: $size-small;
    line-height: $size-small;
    text-align: center;
    color: $white;
    border-radius: $radius-large;
    &.blue {
        background-color: $blue;
    }
    &.grey {
        background-color: $grey;
    }
    &.green {
        background-color: $green;
    }
}

.add-user-permissions { 
    width: 50%;
}