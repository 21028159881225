@use 'components/shared/index.scss' as *;
@use 'sass:color';

.table-body{
    position: relative;
    background-color:$white;
    transition: all .2s;
    box-sizing: border-box;
    min-height: var(--table-row-height);
    &.rounded-bottom {
        border-bottom-left-radius: $size-4xs;
        border-bottom-right-radius: $size-4xs;
    }
}

.table-loading-mask {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: $white;
    transition: all .2s;
    z-index: 3;
    overflow: hidden;
    &.hide {
        opacity: 0;
        visibility: hidden;
        z-index: -1;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 40%; 
        height: 500%; 
        pointer-events: none; 
        background-color: $off-white;
        z-index: 2; 
        filter: blur($size-3xl);
        animation: ripple-mask-move 1.4s linear infinite;
        transition: opacity 0.1s ease-out;
        transform: translateY(-50%) rotateZ(45deg); // Rotate the element to move diagonally
    }
}

.table-loading-mask.fading-pseudo::before {
    opacity: 0;
}

@keyframes ripple-mask-move {
    0% {
        left: -100%;
    }
    100% {
        left: 250%;
    }
}

.table-row {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: var(--table-row-height);
    box-sizing: border-box;    
    border-bottom: 1px solid $table-cell-borders;
    &.empty {
        color: $grey;
    }
    &.no-bottom {
        border-bottom: none;
    }
    &.selected {
        background-color: $secondary-color-lighter;
        &:hover {
            background-color: color.scale($secondary-color-light, $lightness: 10%);
        }
    }
    &:hover {
        background-color: $secondary-color-lightest;
    }
}
