@use 'components/shared/index.scss' as *;


.bucket-container {
  max-height: 60vh;
  height: 60vh;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px dashed #efefef;
  overflow: scroll;

  .spinner {
    position: absolute;
    left: calc(50% - $size-4xs);
    top:  calc(50% - $size-4xs);
  }

  .bucket-row {
    height: $size-xs;
    padding:  $size-4xs $size-3xs;
    cursor: pointer;
    &:hover {
      background: $secondary-color-lightest;
    }
    &:active {
      background: $secondary-color-lighter;
    }

  }
}

.bucket-control-container{
  justify-content:center;

  .margin-top {
    margin-top: $size-xxs;
  }
}


