@use 'components/shared/index.scss' as *;
@use 'sass:color';

.table-row {
    &.total-container {
        position: sticky;
        bottom: 44px;
        height: var(--table-row-height);
        font-weight: bold;
        border: none;
        box-sizing: border-box;
        color: $white;
        border-radius: 0px;
        z-index: 3;
        background-color: $off-white;
        .cell-negative {
            color: color.scale($red, $lightness: 5%);
        }
        &.no-nav-bar, &.listed {
            bottom: 0px;
        }
        &.hide {
            display: none;
        }
        &.small {
            position: relative;
            height: $size-mid;
            min-height: $size-mid;
            .table-row.total {
                background-color: $primary-color-lighter;
            }
        }
    }
    &.total {
        width: 100%;
        height: 100%;
        background-color: $primary-color;
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
        * {color: $white};
        font-weight: bold;
    }
    &.hide {
        .table-cell {
            display: none;
        }
    }
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .table-row.total-container {
        background-color: $grey-darkest;
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
        &.no-nav-bar, &.listed {
            bottom: -4px;
        }
    }
}