@use 'components/shared/index.scss' as *;
@use 'sass:color';

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    :root {
        --size-font-xl: 18px;
        --size-font-large: 16px;
        --size-font-mid: 14px;
        --size-font: 12px;
        --size-font-small: 10px;
        --size-font-xs: 8px;

        --header-height: 40px;

        --input-height: 30px;

        --table-search-bar-height: 50px;
        --table-row-height: 35px;
        --table-nav-height: 30px;
    }
    
    #content {
        width: 100%;
        padding-left: $size-3xs;
        padding-right: 0px;
        box-sizing: border-box;
    }
    
    .grid {
      grid-column-gap: min(max(0px, $size-5xs));    
      grid-row-gap: $size-6xs;
      .five-sixths,
      .three-quarters,
      .five-twelfths,
      .two-thirds,
      .half {
        grid-column: span 12;
        &.first { grid-column: 1 / span 12 }
        &.centered { grid-column: 1 / span 12 }
        &.last { grid-column-end: -1 }
      }
      .half .half {grid-column: span 6;} //for specific cases of quartering.
      .quarter,
      .third,
      .sixth {
        grid-column: span 6;
        &.first { grid-column: 1 / span 6 }
        &.centered { grid-column: 4 / span 6 }
      }
      .phone-two-thirds {
        &.centered { grid-column: 3 / span 8 }
      }
    }

    .input-container.phone, 
    .dropdown-container.phone, 
    .checkbox-container.phone {
      pointer-events: none;
    }

    .phone .input-container.phone .input-cover-display {
      text-align: left;
    }

    .login-form-container {
        width: 85%;
        padding: $size-3xs;
    }
    .login-logo .login-logo-image {
        height: 70px;
    }

    .new-stage-marker-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        height: $size-small;
        margin-top: $size-4xs;
    }
    .new-stage-marker {
        height: $size-small;
        // font-size: calc(var(--size-font) - 1px); //a specific tweak
        line-height: $size-small;
        margin-bottom: 0px;
    }
    .add-user-permissions { 
        width: 100%;
        height: 100%;
        padding-top: $size-3xs;
        .button.half {
            grid-column: span 6; //special case since this section doesn't take up the full modal width;
        }
    }    
    .App.dark {
      .input-container.phone, .dropdown-container.phone {
        .input-cover-display, .input-display {
            background-color: $grey-darker;
            border: 1px solid $grey-darker;
            color: $grey;
        }
      }
      .checkbox-container.phone {
        .checkmark {
          background-color: $grey-dark;
          .checkmark-check * {color: $grey-dark};
          &.checked {
            background-color: $green;
            * { color: $white};
          }
        }
      }
    }
  }