@use 'components/shared/index.scss' as *;

.table-nav-bar-container {
    position: sticky;
    bottom: 0px;
    left: -1px;
    z-index: 3;
    height: var(--table-row-height);
    border-top: 1px solid $grey;
    background-color: $off-white;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    width: 100%;
    overflow: hidden;
    &.hide {
        display: none;
    }
    &.static {
        position: relative;
    }
    &.side-scrollable {
        bottom: 0px;
    }
}

.table-nav-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: var(--table-row-height);
    background-color: $white;
    border-bottom-left-radius: $radius-mid;
    border-bottom-right-radius: $radius-mid;
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .table-nav-bar-container {
        height: var(--table-nav-height);
    }
}
