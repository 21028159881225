@use 'components/shared/index.scss' as *;

.table-header {
    position: sticky;
    top: 0px;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: var(--table-row-height);
    min-height: var(--table-row-height);
    background-color: $white;
    border-bottom: 1px solid;
    border-color: $table-cell-borders;
    box-sizing: border-box;
    color: $primary-color-darker;
    font-weight: bold;
    z-index: 1;
}

.table-header-loading-mask {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: all .2s;
    z-index: 2;
    &.hide {
        opacity: 0;
        z-index: -1;
    }
    .spinner {
        width: $size-xxs;
        height: $size-xxs;
    }
}