@use 'components/shared/index.scss' as *;

.menu-item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    &.profile .menu-item .menu-item-icon {  
        margin-right: $size-xxs;
    }
    * {
        color: $white;
    }
}

.menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    height: $size-xl;
    text-transform: uppercase;
    cursor: pointer;
    &:visited {
        color: $white;
    }
}

.menu-item-icon {
    margin-left: $size-3xs;
    margin-right: $size-5xs;
}

.menu-item-label {
    margin-right: $size-3xs;
    font-size: var(--size-font);
}

.menu-item-children-menu {
    position: absolute;
    top: $size-xl;
    width: 200px;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: $radius-small;
    border-bottom-right-radius:$radius-small;
    background-color: $menu-color;
    overflow: hidden;
    transition: max-height .3s;
    max-height: 0px;
    z-index: 10;
    .menu-item {
        padding-left: $size-3xs;
    }
} 

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    $item-height: 48px;
    .menu-item-container {
        justify-content: flex-start;
        width: 200px;
        height: $item-height;
        max-height: $item-height;
        &.profile {align-items: center};
        &.phone {display: none};
    }
    .menu-item-children-menu {
        position: relative;
        top: 0px;
        justify-content: flex-start;
        height: 0px;
        max-height: 0px;
    } 
    .menu-item-container.open {
        height: auto;
        max-height: 1000px;
        .menu-item-children-menu {
            height: auto;
            max-height: 1000px;
        }
    }
}