@use 'components/shared/index.scss' as *;

.financials-breakdown-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    padding-left:$size-xxs;
    padding-right: $size-xxs;
    .financials-breakdown.grid {
        width: 100%;
        grid-row-gap: $size-5xs;
        grid-column-gap: 0px;
        .bold {font-weight: bold}
        .right {text-align: right}
        .red-text {color: $red}   
        div {
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }    
    .val-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}

.financials-breakdown-title {
    grid-column: span 2;
    text-align: center;
    font-weight: bold;
    font-size: var(--size-font-mid);
    margin-top: $size-4xs;
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .phone .financials-breakdown-container {
        grid-column: span 12 !important;
        .half { grid-column: span 6 }
    }
    .financials-breakdown-title {
        margin-top: $size-6xs;
    }
}