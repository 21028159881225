@use 'components/shared/index.scss' as *;

.card-section-label-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;                
    height: $size-large;
    margin-left: $size-5xs;
    margin-right: $size-4xs;
}

.card-section-label {
    font-size: var(--size-font-large);
}