@use 'components/shared/index.scss' as *;

.table-page-selector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 400px;
    height: $size-mid;
    justify-content: center;
    padding-right: $size-xs;
    transition: width 0.1s ease;
    box-sizing: border-box;
}

.no-table-page-selector-buttons {
    width: 100%;
    border: 1px solid $primary-color; 
    text-align: center;
    background-color: $grey-lighter;
    box-sizing: border-box;
}

.table-page-selector-button {
    width: 100%;
    height: 100%;
    line-height: var(--table-nav-height);
    text-align: center;
    box-shadow: none;
    cursor: pointer;
    background-color: $grey-lightest;
    color: $grey-dark;
    transition: all .3s;
    box-sizing: border-box;
    &.no-vals{
        background-color: $grey-lighter;
        cursor: default
    }
    &.first{
        border-right: 1px solid $table-cell-borders;
        border-top-left-radius: $radius-xs;
        border-bottom-left-radius: $radius-xs;
        &.disabled {
            border-right: 1px solid $grey-lighter;
        }
    }
    &.end {
        width: 100px;
    }
    &.direction.prev {
        width: 150px;
    }
    &.direction.next {
        width: $size-6xl;
    }
    &.last{
        border-left: 1px solid $table-cell-borders;
        border-top-right-radius: $radius-xs;
        border-bottom-right-radius: $radius-xs;
        &.disabled {
            border-left: 1px solid $grey-lighter;
        }
    }
    &.current {
        background-color: $secondary-color;
        color: $white;
    }
    &.disabled {
        color: $grey-lighter;
        background-color: $grey;
        cursor: default;
    }
}

.table-page-selector-numbers, .table-page-selector-concat, .table-page-selector-number {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.table-page-selector-concat, .table-page-selector-number {
    background-color: $white;
    border-right: 1px solid $table-cell-borders;
    line-height: var(--table-nav-height);
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .table-page-selector-container {
        width: 100%;
        max-width: 100%;
        padding: 0px;
    }
    .table-page-selector-button {
        &.first { border-top-left-radius: 0px }; 
        &.last { border-top-right-radius: 0px }; 
        &.end, &.direction.prev,  &.direction.next {
            width: 70px;
        }
    }
}