@use 'components/shared/index.scss' as *;

.table-search-bar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height:var(--table-search-bar-height);
    box-sizing: border-box;
    border-top-right-radius: $radius-mid;
    border-top-left-radius: $radius-mid;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: var(--size-font-large);
    background-color: $primary-color-lighter;
    padding-top: $size-5xs;
    &.hide {
        display: none;
    }

    .input-container.disabled .input-display {
        background-color: $grey-lightest;
    }

    .dropdown-container {
        .dropdown-option, .input-display {
            background-color: $white;
        }
    }
    
    .input-mask {
        background-color: $white;
        &::before {
            background-color: $off-white;
        }
    }
}

.table-search-bar-content.grid {
    height: 100%;
    width: calc(100% - 300px);
    padding-left: $size-3xs;
    > * {
        margin-right: $size-3xs;
    }
}

.table-search-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    max-width: 300px;
    overflow: hidden;
    > * {
        margin-right: $size-3xs;
    }
    .input-container {
        width: 200px;
    }
    .input-display {
        background-color: $white;
        opacity: 1;
    }
}

.table-search-right > .table-search-settings-button.button {
    margin-top: calc($size-xxs + $size-6xs);
    height: var(--input-height);
    width: $size-xl;
    margin-right: $size-4xs;
    .button-text{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .table-search-bar {
        height: auto;
        width: 100%;
        padding: $size-5xs;
        padding-top: $size-6xs;
        border-top-right-radius: $radius;
        border-top-left-radius: $radius;
    }
    .table-search-bar-content.grid {
        width: 49%;
        padding-left: $size-6xs;
        padding-bottom: $size-5xs;
        padding-top: $size-5xs;
        box-sizing: border-box;
        .dropdown-container, .checkbox-container {display: none};
        .date-range-picker {
            grid-column: span 12;
            .input-label {display: none};
        }
    }
    .table-search-right {
        position: relative;
        width: 49%;
        max-width: 100%;
        justify-content: center;
        padding-top: $size-6xs;
        padding-left: 1px;
        padding-right: $size-6xs;
        .input-container {margin: 0px};
        .input-mask {top: 0px};
    }

    .table-search-icon {
        position: absolute;
        right: -12px;
        top: 8px;
        z-index: 6;
        svg {
            width: 15px;
        }
    }
}