@use 'components/shared/index.scss' as *;

.location-settings-padding {
    margin-bottom: $size-xxs;
}

@media (max-width: $breakpoint-phone-width) or (max-height: $breakpoint-phone-width) {
    .phone .location-settings {
        .phone-full {grid-column: span 12};
        .twelfth {grid-column: span 4};
        .active {grid-column: span 4};
        .active-spacer {grid-column: span 8};
    }
}