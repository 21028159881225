@use 'components/shared/index.scss' as *;
@use 'sass:color';

:root {
  --size-font-xl: 24px;
  --size-font-large: 18px;
  --size-font-mid: 16px;
  --size-font: 14px;
  --size-font-small: 12px;
  --size-font-xs: 10px;

  --header-height: 50px;

  --input-height: 36px;

  --table-search-bar-height: 75px;
  --table-row-height: 50px;
  --table-nav-height: 35px;
}

html {
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  background: $off-white;
}

#modal {
  position: absolute;
}

#root {
  height: 100%;
  width: 100%;
}

* {
  font-family: $default-font-family;
  font-size: var(--size-font);
  color: $primary-color-darkest;
}

.App {  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  &.frozen * {
    pointer-events: none !important;
    user-select: none !important;
  }
}

#content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: $size-3xs;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: $size-3xs;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  &.spaced {
    justify-content: space-around;
  }
  &.spread {
    justify-content: space-between;
  }
}

#update-notice {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  opacity: 1;
  width: auto;
  white-space: nowrap;
  height: $size-small;
  line-height: $size-small;
  font-weight: bold;
  border-radius: $size-large;
  &.show {
      padding-left: $size-3xs;
      padding-right: $size-3xs;
      opacity:1;
      transition: opacity .1s;
  }
  &.hide {
      opacity:0;
      transition: opacity .5s;
  }
  &.success {
      background-color: $green;
      color: $white;
  }
  &.fail {
      background-color: $red;
      color: $white;
  }
}

#copied-message {
  position: absolute;
  opacity: 0;
  transition: opacity 50ms ease-in-out;
  background: $primary-color-light;
  pointer-events: none;
  padding: $size-6xs $size-5xs;
  color: $white;
  border: 1px solid $secondary-color-light;
  border-radius: $radius;
  &.show {
    opacity: 1;
    animation: fadeOut 500ms 50ms forwards;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: $radius-small;
}
::-webkit-scrollbar-thumb:hover {
  background-color: $primary-color-lighter;
}

.spinner {
  width: $size-4xs;
  height: $size-4xs;
  border-radius: 50%;
  display: block;
  position: relative;
  border: $size-5xs solid;
  animation: animloader 2s linear infinite;
  &.grey {
    animation: animloader-grey 2s linear infinite;
  }
}

$cleared: rgba(255, 255, 255, 0);

@keyframes animloader {
  0% {
      transform: rotate(0deg);
      border-color: $cleared $cleared $cleared;
  }
  25% {
      border-color: $white $white $cleared $cleared;
  }
  50% {
      border-color: $white $white $white $cleared
  }
  75% {
      border-color: $cleared $cleared $cleared $white
  }
  100% {
      transform: rotate(360deg);
      border-color: $white $cleared $cleared $cleared
  }
}

@keyframes animloader-grey {
  0% {
      transform: rotate(0deg);
      border-color: $grey-lightest $cleared $cleared $cleared;
  }
  25% {
      border-color: $grey-lightest $grey-lightest $cleared $cleared;
  }
  50% {
      border-color: $grey-lightest $grey-lightest $grey-lightest $cleared;
  }
  75% {
      border-color: $cleared $cleared $cleared $grey-lightest;
  }
  100% {
      transform: rotate(360deg);
      border-color: $grey-lightest $cleared $cleared $cleared
  }
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.grid {
  display: grid;
  grid-auto-flow:dense;
  grid-auto-rows: min-content;
  grid-column-gap: min(max(0px, $size-3xs));    
  grid-row-gap: $size-5xs;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-auto-flow: inherit;
  width: 100%;
  height: auto;
  &.no-row-gap {
    grid-row-gap: 0px;
  }
  >.full {
    width: 100%;
    grid-column: span 12;
  }
  >.five-sixths {
    width: 100%;
    grid-column: span 10;
    &.first { grid-column: 1 / span 10 }
    &.centered { grid-column: 2 / span 10 }
    &.last { grid-column-end: -1 }
  }
  >.three-quarters {
    width: 100%;
    grid-column: span 9;
    &.first { grid-column: 1 / span 9 }
    &.last { grid-column-end: -1 }
  }
  >.two-thirds {
    width: 100%;
    grid-column: span 8;
    &.first { grid-column: 1 / span 8 }
    &.centered { grid-column: 3 / span 8 }
    &.last { grid-column-end: -1 }
  }
  >.half {
    width: 100%;
    grid-column: span 6;
    &.first { grid-column: 1 / span 6 }
    &.centered { grid-column: 4 / span 6 }
    &.last { grid-column-end: -1 }
  }
  >.five-twelfths {
    width: 100%;
    grid-column: span 5;
    &.first { grid-column: 1 / span 5 }
    &.last { grid-column-end: -1 }
  }
  >.third {
    width: 100%;
    grid-column: span 4;
    &.first { grid-column: 1 / span 4 }
    &.centered { grid-column: 5 / span 4 }
    &.last { grid-column-end: -1 }
  }
  >.quarter {
    width: 100%;
    grid-column: span 3;
    &.first { grid-column: 1 / span 3 }
    &.last { grid-column-end: -1 }
  }
  >.sixth  {
    width: 100%;
    grid-column: span 2;
    &.first { grid-column: 1 / span 2 }
    &.centered { grid-column: 6 / span 2 }
    &.last { grid-column-end: -1 }
  }
  >.twelfth  {
    width: 100%;
    grid-column: span 1;
    &.first { grid-column: 1 / span 1 }
    &.last { grid-column-end: -1 }
  }
}

.placeholder {
  color: $grey-dark; 
  cursor: default;
}

.App {
  @each $color-name, $color-value in $colors {
      .#{$color-name} {
          background-color: $color-value;
      }
  }
  
  @each $name, $gradient in $gradients {
      .grad-#{$name} {
          background: $gradient;
      }
  }
}